<template>
  <div class="header" v-bind:style="view.header">
    <div class="nav" @click="open()"></div>
    <div class="nav_logo"></div>
    <div class="nav_block" v-bind:style="ng" @click.stop="exit(1)">
      <div class="ng">
        <div class="main" v-bind:style="main" @click.stop="exit(0)">
          <div class="navTitle">万人添书</div>
          <div class="form" v-if="!UserInfo">
            <div class="set">
              <input placeholder="请输入手机号码" v-model="form.PhoneMobile" />
            </div>
            <div class="set">
              <input
                type="number"
                class="code"
                maxlength="5"
                size="4"
                placeholder="请输入验证码"
                v-model="form.NumberCode"
              />
              <div class="GetCode" @click="GetCode" :style="CodeStyle">
                {{ CodeText }}
              </div>
            </div>
          </div>
          <div class="button" v-if="!UserInfo">
            <div class="block" @click="login">登陆</div>
            <div class="block" @click="exit(1)">取消</div>
          </div>
          <div class="User" v-if="UserInfo">
            <div class="block">Hi~ {{ UserInfo.Name }} 欢迎来到添书宇宙</div>
            <div class="block">加入宇宙：{{ UserInfo.NewTime }}</div>
          </div>
          <div class="UserData" v-if="UserInfo">
            <div class="block">点赞：{{ UserInfo.Give }}</div>
            <div class="block">评论：{{ UserInfo.Comment }}</div>
          </div>

          <div class="ObtainData" v-if="UserInfo">
            <div class="block" @click="openGiveSet">点赞详情</div>
            <div class="block" @click="openCommentSet">评论详情</div>
          </div>

          <div class="UserClose" v-if="UserInfo" @click="UserClose">
            退出登陆
          </div>
        </div>
      </div>
    </div>
    <div class="details" v-if="view.details">
      <div class="main">
        <div class="titles">{{ detailsStyle }}</div>
        <div class="view" v-if="detailsStyle == '点赞详情'">
          <div class="block" v-for="(item, index) in detailsData" :key="index">
            <div class="title">文章标题：{{ item.ArticleInfo.Title }}</div>
            <div class="Captain">
              作者：
              <span v-for="(items, indexs) in item.Team" :key="indexs">
                {{ items.Name }}</span
              >
            </div>
            <div class="NewTime">点赞日期：{{ item.NewTime }}</div>
            <div class="getgo">
              <router-link
                :to="{
                  path: '/mobile/browseText/' + item.ArticleId,
                }"
                >跳转>>
              </router-link>
            </div>
          </div>
        </div>
        <div class="view" v-if="detailsStyle == '评论详情'">
          <div class="block" v-for="(item, index) in detailsData" :key="index">
            <div class="title">文章标题：{{ item.ArticleInfo.Title }}</div>
            <div class="Captain">
              作者：
              <span v-for="(items, indexs) in item.Team" :key="indexs">{{
                items.Name
              }}</span>
            </div>
            <div class="NewTime">评论日期：{{ item.NewTime }}</div>
            <div class="getgo">
              <router-link
                :to="{
                  path: '/mobile/browseText/' + item.ArticleId,
                }"
                >跳转>>
              </router-link>
            </div>
          </div>
        </div>
        <div class="close" @click="view.details = false">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
import nav from "@/assets/Image/public/nav_1.png";
import nav_logo_m from "@/assets/Image/mobile/home/nav_logo.png";
import {
  FindGiveSet,
  Setlogin,
  SetCode,
  FindCommentSet,
  fatchUserInfo,
} from "../../../api/header";
import { Dialog, Notify, Button } from "vant";

export default {
  data() {
    return {
      CodeStyle: "",
      CodeTime: 60,
      CodeText: "获取验证码",
      UserInfo: {},
      nav,
      nav_logo_m,
      ng: "",
      main: "left:-30rem,",
      form: {},
      view: {
        header: "",
        details: false,
      },
      detailsStyle: "",
      detailsData: [],
    };
  },
  components: {
    [Dialog.name]: Dialog,
    [Notify.Component.name]: Notify.Component,
    [Button.name]: Button,
  },

  mounted() {
    this.scroll();
    let UserInfo = this.$store.state.UserInfo;
    if (UserInfo.PhoneMobile) {
      this.UserInfos();
      this.UserInfo = UserInfo;
    } else {
      this.UserInfo = false;
    }
  },
  methods: {
    UserInfos() {
      let UserInfo = this.$store.state.UserInfo;
      let Name = UserInfo.Name;
      let PhoneMobile = UserInfo.PhoneMobile;
      fatchUserInfo({ Name, PhoneMobile }).then((res) => {
        let UserInfo = res.UserInfo;
        this.UserInfo = UserInfo;
        this.$store.state.UserInfo = UserInfo;
      });
    },
    NotStr() {
      Dialog.alert({
        title: "系统",
        message: "即将开放",
      });
    },
    openCommentSet() {
      let UserInfo = this.$store.state.UserInfo;
      let Mobile = UserInfo.PhoneMobile;
      this.view.details = true;
      FindCommentSet({ Mobile }).then((res) => {
        this.detailsStyle = "评论详情";
        this.detailsData = res.sum;
      });
    },
    openGiveSet() {
      let UserInfo = this.$store.state.UserInfo;
      let Mobile = UserInfo.PhoneMobile;
      this.view.details = true;
      FindGiveSet({ Mobile }).then((res) => {
        this.detailsStyle = "点赞详情";
        this.detailsData = res.sum;
      });
    },

    GetCode() {
      if (this.CodeText == "获取验证码") {
        let PhoneMobile = this.form.PhoneMobile;
        if (PhoneMobile) {
          SetCode({ PhoneMobile }).then((res) => {
            if (res.code == 0) {
              this.CodeTime = 60;
              this.CodeStyle = "background-color: #dededf;color: black;";
              this.CodeTimes = setInterval(() => {
                let CodeTime = this.CodeTime;
                CodeTime = CodeTime - 1;
                if (this.CodeTime == 0) {
                  clearInterval(this.CodeTimes);
                  this.CodeText = "获取验证码";
                  this.CodeStyle = "";
                } else {
                  this.CodeTime = CodeTime;
                  this.CodeText = "还剩" + CodeTime + "秒可再次获取";
                }
              }, 1000);
              Notify({ type: "success", message: res.message });
            } else {
              Notify({ type: "warning", message: res.message });
            }
          });
        } else {
          Dialog.alert({
            title: "系统",
            message: "请输入手机号码",
          });
        }
      }
    },
    UserClose() {
      Dialog.confirm({
        title: "系统",
        message: "是否退出当前账号？",
      }).then(() => {
        this.$store.state.UserInfo = {};
        this.UserInfo = false;
        Notify({ type: "primary", message: "已退出当前账号" });
        this.exit(1);
      });
    },
    login() {
      let NumberCode = this.form.NumberCode;
      let PhoneMobile = this.form.PhoneMobile;
      if (NumberCode && PhoneMobile) {
        Setlogin({ PhoneMobile, NumberCode }).then((res) => {
          if (res.code == 0) {
            let UserInfo = res.UserInfo;
            this.UserInfo = UserInfo;
            this.$store.state.UserInfo = UserInfo;
            Notify({ type: "success", message: res.message });
            this.exit(1);
          } else {
            Notify({ type: "warning", message: res.message });
          }
        });
      } else {
        Dialog.alert({
          title: "系统",
          message: "手机号码或验证码未输入",
        });
      }
    },
    scroll() {
      window.addEventListener("scroll", () => {
        let top = document.documentElement.scrollTop;
        if (top >= 100) {
          this.view.header =
            "position: fixed;left: 0;top: 0;background-color:#93523a";
        } else {
          this.view.header = "";
        }
      });
    },
    close_view() {
      document.body.style.overflow = "auto";
    },
    open() {
      this.ng = "display:block;";
      setTimeout(() => {
        this.main = "left:0";
        document.body.style.overflow = "hidden";
      }, 1);
    },
    exit(str) {
      if (str == 0) {
        return;
      }
      this.main = "left:-30rem";
      document.body.style.overflow = "auto";
      setTimeout(() => {
        this.ng = "";
      }, 300);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 10%;
  position: relative;
  z-index: 20;

  .nav_logo {
    position: absolute;
    left: 50%;
    width: 7rem;
    height: 3rem;
    margin-left: -4.25rem;
    top: 0;
    background-image: url("../../../assets/Image/public/nav_logo.png");
    background-size: 100% 100%;
  }

  .nav {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-image: url("../../../assets/Image/public/nav_1.png");
    background-size: 100% 100%;
  }

  .nav_block {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 15;
    display: none;

    .ng {
      position: relative;
      width: 100%;
      height: 100%;

      .main {
        position: absolute;
        width: 20rem;
        height: 100%;
        left: -15rem;
        top: 0;
        background-color: #efefef;
        transition: 0.3s;

        a {
          color: black;
          text-decoration: none;
          outline: none;
        }

        .navTitle {
          background-color: #78432e;
          width: 100%;
          height: 4rem;
          line-height: 4rem;
          text-align: center;
          margin-bottom: 0.8rem;
          color: #ffffff;
          font-size: 1rem;
        }

        .form {
          width: 100%;
          height: 8rem;

          .set {
            width: 310px;
            height: 50px;
            position: relative;

            input {
              background: none;
              border: none;
              width: 100%;
              height: 100%;
              outline: none;
              text-indent: 1rem;
              border-bottom: 1px solid #dededf;
            }

            input:focus {
              border: none;
              border-bottom: 1px solid #666666;
            }

            .code {
              width: 10rem;
            }

            .GetCode {
              position: absolute;
              right: 0;
              top: 0.9rem;
              font-size: 0.7rem;
              color: #ffffff;
              border-radius: 0.5rem;
              background-color: #ff725e;
              padding: 0.5rem 1rem;
            }
          }
        }

        .button {
          width: 100%;
          height: 2rem;
          display: flex;
          justify-content: space-around;

          .block {
            background-color: #93523a;
            color: #ffffff;
            font-size: 0.5rem;
            width: 6rem;
            height: 2rem;
            line-height: 2rem;
            border-radius: 0.4rem;
            text-align: center;
          }
        }
      }
    }
  }

  .User {
    width: 100%;
    margin-bottom: 1.7rem;

    .block {
      width: 100%;
      height: 2rem;
      line-height: 2rem;
      margin-bottom: 0.4rem;
      font-size: 0.8rem;
      text-indent: 1rem;
    }
  }

  .UserData {
    width: 17rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .block {
      width: 6rem;
      height: 2rem;
      line-height: 2rem;
      margin-bottom: 0.4rem;
      font-size: 0.8rem;
      text-indent: 1rem;
    }
  }

  .ObtainData {
    width: 17rem;
    margin: 0 auto;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .block {
      background-color: #dededf;
      border: 1px solid #000000;
      width: 6rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 0.8rem;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  .UserClose {
    position: absolute;
    width: 100%;
    height: 3rem;
    background-color: rgba(249, 52, 18, 0.81);
    text-align: center;
    left: 0;
    bottom: 0;
    color: #ffffff;
    line-height: 3rem;
  }

  .details {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 20;

    .main {
      width: 21rem;
      height: 35rem;
      background-color: #ffffff;
      border-radius: 0.6rem;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -10.5rem;
      margin-top: -17.5rem;

      .titles {
        width: 100%;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        color: #ff725e;
      }

      .view {
        width: 100%;
        height: 29rem;
        overflow-y: auto;

        .Captain {
          span {
            margin-left: 2px;
          }
        }

        .block {
          font-size: 0.7rem;
          width: 18rem;
          height: 3rem;
          background-color: #efefef;
          padding: 1rem;
          margin: 0 auto;
          margin-bottom: 0.6rem;
          border-radius: 0.4rem;
          position: relative;

          .getgo {
            a {
              color: rgba(4, 119, 50, 0.81);
            }

            position: absolute;
            right: 0.3rem;
            bottom: 0.5rem;
          }
        }
      }

      .close {
        width: 100%;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        color: #ff725e;
        font-size: 0.8rem;
      }
    }
  }
}
</style>
