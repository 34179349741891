<template>
  <div class="footer">
    <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备19046295号-1</a>
    三驱科技（杭州）有限公司<br />
    Copyright©3QLab.com <span></span>2020<br />
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  height: 4rem;
  color: #ffffff;
  font-size: 0.4rem;
  text-align: center;
  line-height: 1.2rem;
  span {
    width: 1rem;
    display: inline-block;
  }
  a {
    color: #ffffff;
    text-decoration: none;
    display: block;
  }
}
</style>
