import request from "../utils/request";

export const Setlogin = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "login/verification",
    method: "get",
    params: query,
  });
};

export const FindGiveSet = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "user/find/give/set",
    method: "get",
    params: query,
  });
};

export const FindCommentSet = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "user/find/Comment/set",
    method: "get",
    params: query,
  });
};

export const SetCode = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "code/set",
    method: "get",
    params: query,
  });
};

export const fatchUserInfo = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "login/UserInfo",
    method: "get",
    params: query,
  });
};
